<template>
	<div v-show="!showModal">
		<a-spin :spinning="loading">
			<a-table
			        style="margin-top: 10px;"
			    :dataSource="list"
			    :pagination="false"
			    :columns="columns"
			    bordered
				rowKey="id"
			    size="middle"
			>
			    <template #bodyCell="{ column, record, index }">
			        <template v-if="column.key == 'action'">
			            <a-dropdown>
			                <a-button @click.prevent>操作 <Icon icon="DownOutlined"></Icon></a-button>
			                <template #overlay>
			                    <a-menu>
									<div @click="toSee(record)">
										<a-menu-item>
											<a>查看</a>
										</a-menu-item>
									</div>
									<div v-permission="['operation_agreement_list_edit']" @click="toEdit(record)">
										<a-menu-item>
											<a>编辑</a>
										</a-menu-item>
									</div>
			                    </a-menu>
			                </template>
			            </a-dropdown>
			        </template>
			    </template>
			</a-table>
		</a-spin>
	</div>
	<temp v-if="showModal" :id="key" :isSee="isSee" @back="onBack"></temp>
</template>

<script>
	import temp from './temp.vue';
	import { Icon } from "@/components/icon/icon.js";
	import { getAgreementList } from '@/service/modules/agreement.js';
	export default {
		components: { Icon, temp },
		data() {
			return {
				loading: false,
				showModal: false,
				isSee: false,
				list: [],
				columns: [{
				    title: '协议类型',
					dataIndex: 'title'
				}, {
				    title: '操作',
				    key: 'action'
				}],
				key: ''
			}
		},
		created() {
			this.getData();
		},
		methods: {
			onBack() {
				this.showModal = false;
			},
			async getData() {
				this.loading = true;
				let ret = await getAgreementList({
					
				});
				this.loading = false;
				if (ret.code === 200) {
					this.list = ret.data;
				}
			},
			toEdit(item) {
				this.key = item.key;
				this.isSee = false;
				this.showModal = true;
			},
			toSee(item) {
				this.key = item.key;
				this.isSee = true;
				this.showModal = true;
			}
		}
	}
</script>

<style>
</style>
