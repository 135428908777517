import { send } from "../index";

export function getAgreementList(data, opts = {}) {
	//  协议列表
    return send({
        url: "/admin/agreementSetting/list.do",
        method: "POST",
        data,
        ...opts,
    });
}

export function getAgreementDetail(data, opts = {}) {
	//  协议详情
    return send({
        url: "/admin/agreementSetting/detail.do",
        method: "POST",
        data,
        ...opts,
    });
}

export function updateAgreement(data, opts = {}) {
	//  协议修改
    return send({
        url: "/admin/agreementSetting/update.do",
        method: "POST",
        data,
        ...opts,
    });
}